import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as NoPageRefreshLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Survey from '../survey/Survey'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Enterprise from '../enterprise/Enterprise.js'

import { useDispatch, useSelector } from "react-redux";
import {
  signUpAsync,
  selectMessageLevel,
  selectMessage,
  selectAutoSignInUrl,

} from "./globalSignUpSlice";

import { saveSurveyAsync, selectShowSurvey } from '../survey/surveySlice';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const options =[
  "Reklama w radiu/telewizji",
  "Google",
  "Facebook",
  "EmpikPlace",
  "wszystko.pl",
  "erli.pl",
  "SellIntegro",
  "Znajomy polecił",
  "Agencja",
  "Inne"
]

export default function GlobalSignUp() {
  const dispatch = useDispatch();
  const messageLevel = useSelector(selectMessageLevel);
  const message = useSelector(selectMessage);
  const showSurvey = useSelector(selectShowSurvey);

  const autoSignInUrl = useSelector(selectAutoSignInUrl);
  const [buttonDisabled, disableButton] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const signUpData = {
      firstName: data.get("firstName"),
      allegroName: data.get("allegroName") || '',
      phone: data.get("phone"),
      promoCode: data.get("promoCode"),
      email: data.get("email"),
      password: data.get("password"),
      terms: data.get("terms"),
    };

    disableButton(true);
    dispatch(signUpAsync(signUpData));
  };

  if (autoSignInUrl && !showSurvey) {
    window.location.replace(autoSignInUrl);
  }

  const onSurveyClose = () => {
    dispatch(saveSurveyAsync());
  };

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  function getSignUp(){
    return( <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete="given-name"
          name="firstName"
          required
          fullWidth
          id="firstName"
          label="Twoje imię"
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="email"
          label="Twój adres e-mail"
          name="email"
          autoComplete="email"
          onChange={e => disableButton(false)}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          required
          id="phone"
          label="Twój numer telefonu"
          name="phone"
          autoComplete="tel"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          name="password"
          label="Hasło"
          type="password"
          id="password"
          autoComplete="new-password"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
      <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Skąd o nas wiesz?</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="promoCode"
            name="promoCode"
            value={age}
            label="Skąd o nas wiesz?"
            onChange={handleChange}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select>
</FormControl>

      </Grid>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          value="accepted"
          name="terms"
          control={<Checkbox />}
          label={<div>Akceptuję <Link href='https://www.sellintegro.pl/regulamin-uslugi' target="_blank;">regulamin</Link> oraz <Link href="https://www.sellintegro.pl/polityka-prywatnosci" target="blank">politykę prywatności</Link></div>}
          labelPlacement="end"
        />

      </Grid>
      {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox value="allowExtraEmails" color="primary" />}
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
        </Grid> */}
    </Grid>
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      disabled={buttonDisabled}
    >
      Utwórz konto
    </Button>
    <Grid container justifyContent="flex-end">
      <Grid item>
        <NoPageRefreshLink to="/client/sign-in">
          <Link
            href="#"
            variant="body2"
          >
            Masz już konto? Zaloguj się
          </Link>
        </NoPageRefreshLink>
      </Grid>
    </Grid>
  </Box>)
  }
  

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div>

      {messageLevel && <Alert severity={messageLevel}>{message}</Alert>}
      <Survey onClose={onSurveyClose} />

      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <img src="/6349583feeb5b02e7c92e198_sr_logo.svg" width={256} />
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Przenoszenie ofert" {...a11yProps(0)} />
          <Tab label="Wersja Enterprise" {...a11yProps(1)} />
        </Tabs>
        {tabIndex === 0 && getSignUp()}
        {tabIndex === 1 && <Enterprise/>}
       
      </Box>
    </div>
  );
}
