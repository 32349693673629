import urls from "../../urls";


export async function fetchIsSurveyAnswered({ sessionToken, code }) {

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + sessionToken
    }
  };
  const resp = await fetch(`${urls().Survey}?code=${code}`, requestOptions);
  const data = await resp.json();

  if (data.success) {

    return data.exists;
  }

  throw `Faield to check answers ${JSON.stringify(data)}`;

}


export async function fetchSaveSurveyAnswers({ sessionToken, code, answers }) {

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + sessionToken,
    },
    body: JSON.stringify({
      code,
      answersJsonObject: JSON.stringify(answers)
    }),
  }

  const resp = await fetch(`${urls().Survey}`, requestOptions);
  const data = await resp.json();

  if (data.success) {
    return {};
  }

  throw `Faield to save answers ${JSON.stringify(data)}`;
}

