import React from 'react'
import PercentSlider from './PercentSlider'

import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { DialogActions, Button, Typography, Divider, FormGroup, FormControlLabel, Switch, TextareaAutosize } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';


import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { useSelector, useDispatch } from 'react-redux';
import {
    setImportance,
    setToolsSatisfaction,
    setCanEstimateImportance,
    setCanEstimateToolsSatisfaction, selectCanSaveAndExit, 
    setRemarks,
    selectShowSurvey,
    saveSurveyAsync
} from './surveySlice';

function Survey() {

    const dispatch = useDispatch();
    const businessAnswers = useSelector(state => state.survey.businessAnswers);
    const remarks = useSelector(state => state.survey.businessAnswersRemarks);
    const canSaveAndExit = useSelector(selectCanSaveAndExit);
    const canEstimateImportance = useSelector(state => state.survey.canEstimateImportance);
    const canEstimateToolsSatisfaction = useSelector(state => state.survey.canEstimateToolsSatisfaction);
    const displayItems = [
        { 'id': 'newChannels', 'title': 'Pozyskanie nowych kanałów sprzedaży (np. nowe Marketplacy)', 'icon': <TrendingUpIcon /> },
        { 'id': 'moreOrders', 'title': 'Zwiększenie liczby zamówień', 'icon': <TrendingUpIcon /> },
        { 'id': 'keepOrders', 'title': 'Obsługa obecnych zamówień', 'icon': <TrendingFlatIcon /> },
        { 'id': 'reduceCosts', 'title': 'Zmniejszenie/utrzymanie kosztów działalności?', 'icon': <TrendingDownIcon /> },
        { 'id': 'moreProfitPerOrder', 'title': 'Zwiększenie zysku na zamówieniu?', 'icon': <TrendingUpIcon /> },
        { 'id': 'keepProfitPerOrder', 'title': 'Utrzymanie zysku na zamówieniu?', 'icon': <TrendingFlatIcon /> },
        { 'id': 'reduceRisks', 'title': 'Zmniejszenie ryzyk finansowych (np. kary, sprzedaż poniżej kosztów etc)', 'icon': <TrendingDownIcon /> },
    ];

    for (const id in businessAnswers) {
        const displayItem = displayItems.find(e => e.id == id);
        const businessAnswer = businessAnswers[id];

        displayItem.importanceValue = businessAnswer.importanceValue;
        displayItem.toolsSatisfactionValue = businessAnswer.toolsSatisfactionValue;

        displayItem.handleImportanceChange = (e, newValue) => {
            const data = { id, value: newValue };
            dispatch(setImportance(data));
        }

        displayItem.handleToolsSatisfactionChange = (e, newValue) => {
            const data = { id, value: newValue };
            dispatch(setToolsSatisfaction(data));
        }

    }

    const marks1 = [
        {
            value: 0,
            label: 'Żadne',
        },
        {
            value: 25,
            label: 'Małe',
        },
        {
            value: 50,
            label: 'Średnie',
        },
        {
            value: 75,
            label: 'Duże',
        },
        {
            value: 100,
            label: 'Krytyczne',
        },
    ];

    const marks2 = [
        {
            value: 0,
            label: 'Brak',
        },
        {
            value: 25,
            label: 'Małe',
        },
        {
            value: 50,
            label: 'Średnie',
        },
        {
            value: 75,
            label: 'Duże',
        },
        {
            value: 100,
            label: 'Bardzo duże',
        },
    ];


    const gridItems = [];
    displayItems.forEach(i => {

        gridItems.push(<Grid item xs={6} key={`title.${i.id}`} ><div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', }}>{i.icon}&nbsp;<span>{' '}{i.title}</span></div>  </Grid>);
        gridItems.push(<Grid item xs={3} key={`importance.${i.id}`}><PercentSlider marks={marks1} enabled={canEstimateImportance} color={'success'} colorNumber={`rgb(0,${90 + i.importanceValue * 1.6},0)`} value={i.importanceValue} handleChange={i.handleImportanceChange} /></Grid>);
        gridItems.push(<Grid item xs={3} key={`toolsSatisfaction.${i.id}`}><PercentSlider  marks={marks2} enabled={canEstimateToolsSatisfaction} color={'warning'} colorNumber={`rgb(${90 + i.toolsSatisfactionValue * 1.5},${50 + i.toolsSatisfactionValue * 1.5},0)`} value={i.toolsSatisfactionValue} handleChange={i.handleToolsSatisfactionChange} /></Grid>);
    });


    const handleCanEstimateImportanceChange = (e, newValue) => {

        dispatch(setCanEstimateImportance(!newValue));
    }

    const handleCanEstimateToolsSatisfactionChange = (e, newValue) => {

        dispatch(setCanEstimateToolsSatisfaction(!newValue));
    }

    const showSurvey = useSelector(selectShowSurvey);

    const onSurveyClose = () => {
        dispatch(saveSurveyAsync());
      };

    if(!showSurvey) return <div />

    return (

        <Dialog open={true} fullWidth={true} fontSize={12} maxWidth={'xl'}>
            <DialogTitle>
                <Grid container>
                    <Grid item >
                        <Typography variant='h2'>
                            <EmojiPeopleIcon fontSize='100%' />
                        </Typography>

                    </Grid>
                    <Grid item>
                        <Typography variant='h3'>Poznajmy się lepiej!</Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">nie chcemy dodawać funkcji w obszarach, które nie będą dla Ciebie przydatne...</Typography>
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent>
                <Divider variant="middle" />
                <Grid container spacing={4} component="main" alignItems="center">
                    <Grid item xs={6} alignItems="left" textAlign={'left'} key="area" >
                        <Typography mt={2} variant="h5">Obszar</Typography>
                    </Grid>
                    <Grid item xs={3} key="importance">

                        <Typography mt={2} variant="h5">👇 Znaczenie obszaru dla Twojego biznesu? </Typography>
                        
                    </Grid>
                    <Grid item xs={3} key="toolsSatisfaction">
                        <Typography mt={2} variant="h5">👇 Zadowolenie ze wsparcia narzędzi IT w obszarze?</Typography>
                        
                    </Grid>
                    {gridItems.map(i => i)}



                    <Grid item xs={6} alignItems="left" textAlign={'left'} key="person">
                        <TextareaAutosize
                            aria-label="empty textarea"
                            minRows={2}
                            placeholder="Uwagi do ankiety"
                            value={remarks}
                            onChange={e => dispatch(setRemarks(e.target.value))}
                            style={{ width: '90%' }}
                        />
                    </Grid>
                    <Grid item xs={3} alignItems="center" textAlign={'right'} key="importanceSum">
                        <FormGroup>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <FormControlLabel control={<Switch checked={!canEstimateImportance} onChange={handleCanEstimateImportanceChange} />} label="Nie jestem osobą zorietowaną w powyższej tematyce" />
                            </Typography>
                        </FormGroup>

                    </Grid>
                    <Grid item xs={3} alignItems="right" textAlign={'right'} key="actions">
                        <FormGroup>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <FormControlLabel control={<Switch checked={!canEstimateToolsSatisfaction} onChange={handleCanEstimateToolsSatisfactionChange} />} label="Nie jestem osobą zorietowaną w powyższej tematyce" />
                            </Typography>
                        </FormGroup>

                    </Grid>



                </Grid>
                <Box sx={{ m: 2 }}>
                    <Divider variant="middle" spacing={2} />
                </Box>
                <Grid container spacing={4} component="main" alignItems="center">
                    <Grid item xs={12} alignItems="center" textAlign={'center'} key="actions">
                    <Button variant="outlined" disabled={!canSaveAndExit} onClick={onSurveyClose} > {canSaveAndExit && 'Wyślij & Zamknij'} {!canSaveAndExit && 'Wybierz wrtości suwakami powyżej ⬆️'}  </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

export default Survey