import React from "react";

import "./globalSignIn.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { Link as NoPageRefreshLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Survey from '../survey/Survey'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from "@mui/material/Button";
import Enterprise from '../enterprise/Enterprise.js'
import { useDispatch, useSelector } from "react-redux";
import {
  signInAsync,
  selectMessageLevel,
  selectMessage,
  selectAutoSignInUrl,
} from "./globalSignInSlice";


import { saveSurveyAsync, selectShowSurvey } from '../survey/surveySlice';


function GlobalSignIn() {
  const dispatch = useDispatch();
  const messageLevel = useSelector(selectMessageLevel);
  const message = useSelector(selectMessage);
  const showSurvey = useSelector(selectShowSurvey);

  const autoSignInUrl = useSelector(selectAutoSignInUrl);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    var signInData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    dispatch(signInAsync(signInData));
  };

  if (autoSignInUrl && !showSurvey) {
    window.location.replace(autoSignInUrl);
  }

  const onSurveyClose = () => {
    dispatch(saveSurveyAsync());
  };

  function getSignIn(){
    return(<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
    <TextField
      margin="normal"
      required
      fullWidth
      id="email"
      label="Twój adres e-mail"
      name="email"
      autoComplete="email"
      autoFocus
    />
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Hasło"
      type="password"
      id="password"
      autoComplete="current-password"
    />
    {/* <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Zapamiętaj mnie"
      /> */}
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
    >
      Zaloguj
    </Button>
    <Grid container>
      <Grid item xs>
        <NoPageRefreshLink to="/client/reset-password">
          <Link href="#"
            variant="body2"
          >
            Zapomniałeś hasła?
          </Link>
        </NoPageRefreshLink>
      </Grid>
      <Grid item>
        <NoPageRefreshLink to="/client/sign-up">
          <Link
            href="#"
            variant="body2"
          >
            {"Nie masz konta? Utwórz"}
          </Link>
        </NoPageRefreshLink>
      </Grid>
    </Grid>
  </Box>)
  }

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  //see: https://mui.com/material-ui/getting-started/templates/#main-content
  return (
    <div>
      {messageLevel && <Alert severity={messageLevel}>{message}</Alert>}
      <Survey onClose={onSurveyClose} />
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/6349583feeb5b02e7c92e198_sr_logo.svg" width={256} />
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Przenoszenie ofert" {...a11yProps(0)} />
          <Tab label="Wersja Enterprise" {...a11yProps(1)} />
        </Tabs>
        {tabIndex === 0 && getSignIn()}
        {tabIndex === 1 && <Enterprise/>}
      </Box>
    </div>
  );
}

export default GlobalSignIn;
