import urls from "../../urls";

export async function fetchSignIn({ email, password }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  };
  try {
    const resp = await fetch(urls().SignInOnly, requestOptions);
    const data = await resp.json();

    if (data.success) {
      console.log(data);

      return data;
    }
    return { ...data, success: false };
  } catch (err) {
    console.log(err);
    return { success: false, status: "ConnectionFailed" };
  }

  // return new Promise((resolve) =>
  //   setTimeout(() => {
  //     let data;
  //     if (email === "a" && password === "b") {
  //       data = { success: true, status: "Ok", sessionToken: "abc" };
  //     } else {
  //       data = { success: false, status: "SignInFailed" };
  //     }

  //     resolve(data);
  //   }, 300)
  // );
}
