import { fetchSignIn } from "./globalSignInApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchIsSurveyAnswered } from "../survey/surveyApi";
import { setShowSurvey } from '../survey/surveySlice';
import { setEngineSettingUp, setSesstionToken } from '../globalSignUp/globalSignUpSlice';
import { fetchSetUp } from '../globalSignUp/globalSignUpApi';

// chcemy umozliwoc logowanie
// jak otrzymamy token JWT do glownego systemu to otrzymujemy to otrzymuje token do wejsa do kolejengo systemu
// token = krotki JWT, ktorki tken do wejsca do kolejnego systemu
// system w tle calluje glowny system i potrzymuje long-term token// flow w reac

const initialState = {
  email: "",
  password: "",
  messageLevel: "",
  message: "",
  status: "idle",
  sessionToken: "",
  signInError: "",
  autoSignInUrl: ""
};

export const selectMessageLevel = (state) => state.signIn.messageLevel;
export const selectMessage = (state) => state.signIn.message;
export const selectSessionToken = (state) => state.signIn.sessionToken;
export const selectAutoSignInUrl = (state) => state.signIn.autoSignInUrl;

export const signInAsync = createAsyncThunk(
  "globalSignIn/signIn",
  async (signInData, { dispatch }) => {
    const response = await fetchSignIn(signInData);

    if (response.success) {
      dispatch(setSesstionToken(response.sessionToken));

      const isAnswered = await fetchIsSurveyAnswered({ sessionToken: response.sessionToken, code: 'generalAreasImportanceVsItToolsSatisfaction1' });
      if (!isAnswered) {
        // show surver generalAreasImportanceVsItToolsSatisfaction1
        dispatch(setShowSurvey(true));
      }

      dispatch(setEngineSettingUp());
      return await fetchSetUp({ sessionToken: response.sessionToken });
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const globalSignInSlice = createSlice({
  name: "globalSignIn",
  initialState,
  reducers: {

  },
  // https://redux-toolkit.js.org/api/createAsyncThunk
  extraReducers: (builder) => {
    builder
      .addCase(signInAsync.pending, (state) => {
        state.status = "inProgress";
        state.messageLevel = "info";
        state.message = "Logowanie ...";
      })
      .addCase(signInAsync.fulfilled, (state, action) => {
        console.log("signInAsync.fulfilled");

        state.status = "idle";

        if (action.payload.success) {
          state.autoSignInUrl = action.payload.autoSignInUrl;
          state.messageLevel = "success";
          state.message = "🚀 Zalogowano!";
        } else {
          state.signInError = action.payload.status;
          state.messageLevel = "warning";
          state.message = "Niepoprawny email lub hasło";

          if (action.payload.message) {
            state.message = action.payload.message;
          } else {

            switch (action.payload.status) {
              case "EmailUnknown":
                state.message = "E-mail nie jest powiązany z kontem SellRocket";
                break;

              case "ConnectionFailed":
                state.message = "Wystąpił problem z połączeniem - spróbuj ponownie za jakiś czas";
                break;
            }
          }
        }
      })
      .addCase(signInAsync.rejected, (state, action) => {
        console.log("signInAsync.rejected");

        state.status = "idle";
        state.messageLevel = "warning";
        state.message = "Błąd połączenia";
        console.log(action.error);
      });
  },
});

export const { checkPasswordPolicy } = globalSignInSlice.actions;

export default globalSignInSlice.reducer;
