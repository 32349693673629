import urls from "../../urls";

export async function fetchSetUp({ sessionToken }) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionToken
      }
    };
    const resp = await fetch(urls().SetUpOnly, requestOptions);
    const data = await resp.json();

    if (data.success) {

      return data;
    }
    return { ...data, success: false };
  } catch (err) {
    console.log(err);
    return { success: false, status: "ConnectionFailed" };
  }
}


// A mock function to mimic making an async request for data
export async function fetchSignUp({ email, password, allegroName, phone, promoCode, firstName, terms }) {
  if (!firstName || firstName.length < 1) {
    return { success: false, status: "Failed", message: "Brak imienia" };
  }
  else if (!email || email.length < 1) {
    return {
      success: false,
      status: "Failed",
      message: "Brak adresu email",
    };
  } else if (!password) {
    return { success: false, status: "Failed", message: "Brak hasła" };
  } else if (password.length < 8) {
    return {
      success: false,
      status: "Failed",
      message: "Hasło jest za krótkie",
    };

  } else if (!terms || terms.length < 1) {
    return {
      success: false,
      status: "Failed",
      message: "Aby się zarejestrować wymagana jest akceptacja regulaminu oraz polityki prywatności.",
    };
  } else {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password,
        allegroName,
        firstName,
        phone,
        promoCode,
        terms
      }),
    };

    try {
      const resp = await fetch(urls().SignUpOnly, requestOptions);
      const data = await resp.json();

      console.log({ api: 'api', ...data });
      if (data.success) {

        return data;
      }
      return { ...data, success: false };
    } catch (err) {
      console.log(err);
      return { success: false, status: "ConnectionFailed" };
    }
  }
}
