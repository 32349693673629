import React from "react";
import "./App.css";
import GlobalSignIn from "./features/globalSignIn/GlobalSignIn";
import GlobalSignUp from "./features/globalSignUp/GlobalSignUp";
import GlobalResetPassword from "./features/globalResetPassword/GlobalResetPassword";
import SetNewPassword from "./features/setNewPassowrd/SetNewPassword";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Copyright from "./features/common/Copyright";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


import SurveyDemo from './features/survey/SurveyDemo';


export default function App() {
  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <BrowserRouter>
            <Routes>
              {/* old, cached redirects to index.html*/}
              <Route path="/index.html" element={<GlobalSignIn />} />
              <Route path="/" element={<GlobalSignIn />} />
              <Route path="/survey" element={<SurveyDemo />} />
              <Route path="/client/sign-up" element={<GlobalSignUp />} />
              <Route path="/client/sign-in" element={<GlobalSignIn />} />
              <Route path="/client/reset-password" element={<GlobalResetPassword />} />
              <Route path="/client/password-reset/:token" element={<SetNewPassword />} />
            </Routes>
          </BrowserRouter>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(/6349509035d9db7fc56b8bbe_sr_Artboard%203.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            border: 'inset 7vw transparent'

          }}
        >
        </Grid>
      </Grid>
    </ThemeProvider >
  );
}

//export default App;
