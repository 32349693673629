import React from 'react'
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

function Copyright(props) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        {new Date().getFullYear()}
        {" SellIntegro Sp. z o. o."}
        <br />
        <Link color="inherit" href="https://sellrocket.pl/">
          SellRocket!
        </Link>{" "}
      </Typography>
    );
  }
  
export default Copyright