import React from 'react'
import { Paper, Box, Typography, Link } from '@mui/material';




const Enterprise = () => {
  return (
    <Box  sx={{ mt: 1 }}>
        <Paper sx={{padding:2, margin:2,textAlign: 'center'}}>
            <Typography variant="h6" sx={{padding:1, margin:1}}>
                Wersja Enterprise, to nie tylko przenoszenie ofert!
            </Typography>
            <Typography variant="body1" sx={{padding:1, margin:1}}>
            Zdajemy sobie sprawę, że wielu naszych klientów wymaga dedykowanej opieki i zaawansowanych zmian w naszych integracjach. Chcąc wyjść na przeciw tego typu problemom tworzymy rozwiązania
            szyte na miarę. Wykonamy dedykowane modyfikacje i zaawansowane integracje, zajmiemy się także wsparciem oraz wdrożeniem rozwiązań. Nawet w przypadku zaawansowanych systemów, które wymagają stałej opieki programisty.
            </Typography>
            <Link href="https://www.sellrocket.pl/#contact" target="_blank" rel="noopener noreferrer" sx={{padding:1, margin:1}}>
                Zapraszamy do kontaktu z naszym zespołem 
            </Link>
        </Paper>
    </Box>
  )
}

export default Enterprise