import React from 'react'
import Slider from "@mui/material/Slider"
import Grid from '@mui/material/Grid';


function PercentSlider({ color, colorNumber, value, handleChange, enabled, marks }) {

    
    

    return (

        <Grid container spacing={6} alignItems="center">
            <Grid item xs>
                <Slider marks={marks} aria-label="Impact" disabled={!enabled} size={'small'} value={value} onChange={handleChange} color={color} valueLabelDisplay="auto" />
            </Grid>
            <Grid item color={colorNumber}>
                {/* {value} */}
            </Grid>
        </Grid>

    )
}

export default PercentSlider