import { configureStore } from "@reduxjs/toolkit";
import globalSignIn from "../features/globalSignIn/globalSignInSlice";
import globalSignUp from "../features/globalSignUp/globalSignUpSlice";
import globalResetPassword from "../features/globalResetPassword/globalResetPasswordSlice";
import setNewPassowrd from "../features/setNewPassowrd/setNewPasswordSlice";
import navigation from "../features/navigation/navigationSlice";
import survey from "../features/survey/surveySlice";

export const store = configureStore({
  reducer: {
    signIn: globalSignIn,
    signUp: globalSignUp,
    resetPassword: globalResetPassword,
    setPassword: setNewPassowrd,
    navigation: navigation,
    survey: survey
  },
});
