import React from 'react'
import Survey from './Survey'

import { useDispatch } from 'react-redux'

import { setShowSurvey } from './surveySlice'

function SurveyDemo() {


    const dispatch = useDispatch();

    dispatch(setShowSurvey(true));
    return (
        <div><Survey /></div>
    )
}

export default SurveyDemo