import urls from "../../urls";

export async function fetchSetNewPassword({ token, password }) {
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": "Bearer "+ token},
    body: JSON.stringify({
      password: password,
    }),
  };
  try {
    const resp = await fetch(urls().SetNewPassword, requestOptions);
    const data = await resp.json();

    if (data.success) {
      console.log(data);

      return data;
    }
    return { ...data, success: false };
  } catch (err) {
    console.log(err);
    return { success: false, status: "ConnectionFailed" };
  }
}