import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchResetPassword } from "./globalResetPasswordApi"

const initialState = {
    email: "",
    messageLevel: "",
    message: "",
    status: "idle",
  };

export const selectMessageLevel = (state) => state.resetPassword.messageLevel;
export const selectMessage = (state) => state.resetPassword.message;

export const resetPasswordAsync = createAsyncThunk(
  "globalResetPassword/resetPassword",
  async (email) =>{
    const response = await fetchResetPassword(email);
    
    return response;
  }
)

export const globalResetPasswordSlice = createSlice({
    name: "globalResetPassword",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(resetPasswordAsync.pending, (state) => {
          console.log("resetPasswordAsync.inProgress");
          state.status = "inProgress";
          state.messageLevel = "info";
          state.message = "Restowanie ...";
        })
        .addCase(resetPasswordAsync.fulfilled, (state, action) => {
          console.log("resetPasswordAsync.fulfilled");
  
          state.status = "idle";
  
          if (action.payload.success) {
            state.messageLevel = "success";
            state.message = "Na podany e-mail został wysłany link do zresetowania hasła";
          } else {
            state.messageLevel = "warning";
            state.message = "Niepoprawny email";
  
            if (action.payload.message) {
              state.message = action.payload.message;
            } else {
  
              switch (action.payload.status) {
                case "EmailUnknown":
                  state.message = "E-mail nie jest powiązany z kontem SellRocket";
                  break;
  
                case "ConnectionFailed":
                  state.message = "Wystąpił problem z połączeniem - spróbuj ponownie za jakiś czas";
                  break;

                case "InvalidEmail":
                  state.message = "Niepoprawny format adresu e-mail";
                  break;
    
                  default:
                    state.message = "Wystąpił nieznany błąd";
                    break;
              }
            }
          }
        })
        .addCase(resetPasswordAsync.rejected, (state, action) => {
          console.log("resetPasswordAsync.rejected");
  
          state.status = "idle";
          state.messageLevel = "warning";
          state.message = "Błąd połączenia";
          console.log(action.error);
        });
    },
})

export default globalResetPasswordSlice.reducer;