import { fetchSignUp, fetchSetUp } from "./globalSignUpApi";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchIsSurveyAnswered } from "../survey/surveyApi";
import { setShowSurvey } from '../survey/surveySlice';

// chcemy umozliwoc logowanie
// jak otrzymamy token JWT do glownego systemu to otrzymujemy to otrzymuje token do wejsa do kolejengo systemu
// token = krotki JWT, ktorki tken do wejsca do kolejnego systemu
// system w tle calluje glowny system i potrzymuje long-term token// flow w reac

const initialState = {
  email: "",
  password: "",
  messageLevel: "",
  message: "",
  status: "idle",
  sessionToken: "",
  signUpError: "",
  autoSignInUrl: ""
};

export const selectMessageLevel = (state) => state.signUp.messageLevel;
export const selectMessage = (state) => state.signUp.message;
export const selectSessionToken = (state) => state.signUp.sessionToken;
export const selectAutoSignInUrl = (state) => state.signUp.autoSignInUrl;


const minPasswordLength = 8;

export const signUpAsync = createAsyncThunk(
  "globalSignUp/signUp",
  async (signUpData, { dispatch }) => {
    const response = await fetchSignUp(signUpData);

    if (response.success) {
      dispatch(setSesstionToken(response.sessionToken));

      const isAnswered = await fetchIsSurveyAnswered({ sessionToken: response.sessionToken, code: 'generalAreasImportanceVsItToolsSatisfaction1' });
      if (!isAnswered) {
        // show surver generalAreasImportanceVsItToolsSatisfaction1
        dispatch(setShowSurvey(true));
      }

      dispatch(setEngineSettingUp());
      return await fetchSetUp({ sessionToken: response.sessionToken });
    }
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);



export const globalSignUpSlice = createSlice({
  name: "globalSignUp",
  initialState,
  reducers: {

    setSesstionToken: (state, action) => {
      state.sessionToken = action.payload;
    },
    setEngineSettingUp: (state, action) => {

      state.messageLevel = "info";
      state.message = `👍 Konto utworzone! Teraz przygotowujemy do startu Twoją rakietę 🚀🚀🚀 - może zająć kilka minut ⏲️...`;
    },
    checkPasswordPolicy: (state, action) => {
      // check if password is ok to use
      state.canShowPasswordWarnings = true;
      if (action.password.length >= minPasswordLength) {
        state.passwordWarning = "";
        return;
      }

      state.messageLevel = "warning";
      state.message = `Hasło musi mięc co najmniej ${minPasswordLength} znaków`;
    },
  },
  // https://redux-toolkit.js.org/api/createAsyncThunk
  extraReducers: (builder) => {
    builder
      .addCase(signUpAsync.pending, (state) => {
        state.status = "inProgress";
        state.messageLevel = "info";
        state.message = "Tworzenie konta ...";
      })
      .addCase(signUpAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload.success) {
          state.autoSignInUrl = action.payload.autoSignInUrl;
          state.messageLevel = "success";
          state.message = "🚀 Rakieta gotowa !!";
        } else {
          state.signUpError = action.payload.status;
          state.sessionToken = "";
          state.messageLevel = "warning";
          state.message = "Utworzenie konta nie powiodło się";

          if (action.payload.message) {
            state.message = action.payload.message;
          } else {
            switch (action.payload.status) {
              case "EmailAlreadyUsed":
                state.message = "E-mail jest już zajęty";
                break;

              case "InvalidEmail":
                state.message = "Niepoprawny format adresu e-mail";
                break;

              case "PasswordTooShort":
                state.message = "Hasło jest za krótkie";
                break;

              case "ConnectionFailed":
                state.message =
                  "Wystąpił problem z połączeniem - spróbuj ponownie za jakiś czas";
                break;
            }
          }
        }
      })
      .addCase(signUpAsync.rejected, (state, action) => {
        console.log("signUpAsync.rejected");

        state.status = "idle";
        state.messageLevel = "warning";
        state.message = "Błąd połączenia";
        console.log(action.error);
      });
  },
});

export const { checkPasswordPolicy, setEngineSettingUp, setSesstionToken } = globalSignUpSlice.actions;

export default globalSignUpSlice.reducer;
