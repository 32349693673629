import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import { Link as NoPageRefreshLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";

import {
    selectMessageLevel,
    selectMessage,
    resetPasswordAsync
} from "./globalResetPasswordSlice";

export default function GlobalResetPassword() {
    const dispatch = useDispatch();
    const messageLevel = useSelector(selectMessageLevel);
    const message = useSelector(selectMessage);
    var isResetButtonDisable = (messageLevel === "success");

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        var signInData = {
            email: data.get("email"),
        };

        dispatch(resetPasswordAsync(signInData));
    };

    return (
        <div>
            {messageLevel && <Alert severity={messageLevel}>{message}</Alert>}

            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >

                <img src="/6349583feeb5b02e7c92e198_sr_logo.svg" width={256} />
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Twój adres e-mail"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Button
                        type="submit"
                        fullWidth
                        disabled={isResetButtonDisable}
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Resetuj hasło
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <NoPageRefreshLink to="/client/sign-in">
                                <Link
                                    href="#"
                                    variant="body2"
                                >
                                    Masz już konto? Zaloguj się
                                </Link>
                            </NoPageRefreshLink>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}