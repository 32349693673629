import urls from "../../urls";

export async function fetchResetPassword({ email }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email
    }),
  };
  try {
    const resp = await fetch(urls().SendResetPasswordEmail, requestOptions);
    const data = await resp.json();

    if (data.success) {
      console.log(data);

      return data;
    }
    return { ...data, success: false };
  } catch (err) {
    console.log(err);
    return { success: false, status: "ConnectionFailed" };
  }
}