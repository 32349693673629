import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSetNewPassword } from "./setNewPasswordApi"

const initialState = {
    email: "",
    messageLevel: "",
    message: "",
    status: "idle",
  };

export const selectMessageLevel = (state) => state.setPassword.messageLevel;
export const selectMessage = (state) => state.setPassword.message;

export const setNewPasswordAsync = createAsyncThunk(
  "setNewPassword/setPassword",
  async (setPasswordData) =>{
    const response = await fetchSetNewPassword(setPasswordData);
    return response;
  }
)

export const setNewPasswordSlice = createSlice({
    name: "setNewPassword",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(setNewPasswordAsync.pending, (state) => {
          console.log("setNewPasswordAsync.inProgress");
          state.status = "inProgress";
          state.messageLevel = "info";
          state.message = "Ustawianie nowego hasła ...";
        })
        .addCase(setNewPasswordAsync.fulfilled, (state, action) => {
          console.log("setNewPasswordAsync.fulfilled");
  
          state.status = "idle";
  
          if (action.payload.success) {
            state.messageLevel = "success";
            state.message = "Hasło zostało zresetowane. Możesz się teraz zalogować.";
          } else {
            state.messageLevel = "warning";
            state.message = "Wystąpił nieznany błąd.";
  
            if (action.payload.message) {
              state.message = action.payload.message;
            } else {
  
              switch (action.payload.status) {
  
                case "PasswordTooShort":
                    state.message = "Podane hasło jest za krótkie. Wpisz przynajmnniej 8 znakowe hasło.";
                    break;

                case "ErrorWhileChangingPassword":
                  state.message = "Wystąpił błąd podczas zmiany hasła - spróbuj ponownie później";
                  break;

                case "ConnectionFailed":
                  state.message = "Wystąpił problem z połączeniem - spróbuj ponownie za jakiś czas";
                  break;
    
                default:
                  state.message = "Wystąpił nieznany błąd";
                  break;
              }
            }
          }
        })
        .addCase(setNewPasswordAsync.rejected, (state, action) => {
          console.log("setNewPasswordAsync.rejected");
  
          state.status = "idle";
          state.messageLevel = "warning";
          state.message = "Błąd połączenia";
          console.log(action.error);
        });
    },
})

export default setNewPasswordSlice.reducer;