import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSaveSurveyAnswers, fetchIsSurveyAnswered } from './surveyApi'

// chcemy umozliwoc logowanie
// jak otrzymamy token JWT do glownego systemu to otrzymujemy to otrzymuje token do wejsa do kolejengo systemu
// token = krotki JWT, ktorki tken do wejsca do kolejnego systemu
// system w tle calluje glowny system i potrzymuje long-term token// flow w reac

function initialBusinessAnswer() {
    return {
        'importanceValue': 0,
        'toolsSatisfactionValue': 0
    }
}

export function buildInitialState() {
    return {
        businessAnswers: {
            newChannels: initialBusinessAnswer(),
            moreOrders: initialBusinessAnswer(),
            keepOrders: initialBusinessAnswer(),
            reduceCosts: initialBusinessAnswer(),
            moreProfitPerOrder: initialBusinessAnswer(),
            keepProfitPerOrder: initialBusinessAnswer(),
            reduceRisks: initialBusinessAnswer()
        },
        canEstimateImportance: true,
        canEstimateToolsSatisfaction: true,
        businessAnswersRemarks: '',
        showSurvey: false

    };
}

const initialState = buildInitialState();

export const selectShowSurvey = (state) => state.survey.showSurvey;

function sumImportanceInternal(state) {
    let res = 0;
    const answers = state.businessAnswers;

    for (const id in answers) {
        res += answers[id].importanceValue;
    }
    return res;
}

function sumToolsSatisfactionInternal(state) {
    let res = 0;
    const answers = state.businessAnswers;
    for (const id in answers) {
        res += answers[id].toolsSatisfactionValue;
    }
    return res;
}

export const saveSurveyAsync = createAsyncThunk(
    "survey/saveSurveyAsync",
    async (signUpData, { dispatch, getState }) => {

        console.log('saveSurveyAsync');

        const state = getState();
        await fetchSaveSurveyAnswers({ sessionToken: state.signUp.sessionToken, code: 'generalAreasImportanceVsItToolsSatisfaction1', answers: state.survey });
        dispatch(setShowSurvey(false));
    }
);



export const surveySlice = createSlice({
    name: "survey",
    initialState,
    reducers: {
        setCanEstimateImportance: (state, action) => {
            state.canEstimateImportance = action.payload;
        },
        setCanEstimateToolsSatisfaction: (state, action) => {
            state.canEstimateToolsSatisfaction = action.payload;
        },
        setImportance: (state, action) => {
            const payload = action.payload;
            const answer = state.businessAnswers[payload.id];
            answer.importanceValue = payload.value;
        },
        setToolsSatisfaction: (state, action) => {
            const payload = action.payload;
            const answer = state.businessAnswers[payload.id];
            answer.toolsSatisfactionValue = payload.value;
        },
        setRemarks: (state, action) => {
            state.businessAnswersRemarks = action.payload;
        },
        setShowSurvey: (state, action) => {
            state.showSurvey = action.payload;
        },

    }
    // https://redux-toolkit.js.org/api/createAsyncThunk

});

export const sumImportance = (globalState) => {
    return sumImportanceInternal(globalState.survey);
};

export const sumToolsSatisfaction = (globalState) => {
    return sumToolsSatisfactionInternal(globalState.survey);
};


export const selectCanSaveAndExit = (globalState) => {

    const state = globalState.survey;

    if (state.canEstimateImportance) {
        const sum = sumImportanceInternal(state);
        if (sum == 0) return false;
    }

    if (state.canEstimateToolsSatisfaction) {
        const sum = sumToolsSatisfactionInternal(state);
        if (sum == 0) return false;
    }

    return true;
};



export const { setImportance, setToolsSatisfaction, setCanEstimateImportance, setCanEstimateToolsSatisfaction, setRemarks, setShowSurvey } = surveySlice.actions;

export default surveySlice.reducer;
