import { createSlice } from "@reduxjs/toolkit";

// chcemy umozliwoc logowanie
// jak otrzymamy token JWT do glownego systemu to otrzymujemy to otrzymuje token do wejsa do kolejengo systemu
// token = krotki JWT, ktorki tken do wejsca do kolejnego systemu
// system w tle calluje glowny system i potrzymuje long-term token// flow w reac

const initialState = {
  currentScreen: "globalSignUp",
};

export const selectCurrentScreen = (state) => state.navigation.currentScreen;

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    showSignUp: (state, action) => {
      state.currentScreen = "globalSignUp";
    },
    showSignIn: (state, action) => {
      state.currentScreen = "globalSignIn";
    },
    showResetPassword: (state, action) => {
      state.currentScreen = "globalResetPassword";
    },
  },
});

export const { showSignUp, showSignIn, showResetPassword } = navigationSlice.actions;

export default navigationSlice.reducer;
