function urls() {
  const isDev = document.location.href.startsWith('http://localhost');
  const root = isDev ? 'https://localhost:7126' : 'https://sellrocket-api.sellintegro.com'

  return {
    SignIn: `${root}/UserSagas/SignInAndSetUp`,
    SignUp: `${root}/UserSagas/SignUpAndSetUp`,
    SignUpOnly: `${root}/UserSagas/SignUpOnly`,
    SignInOnly: `${root}/UserSagas/SignInOnly`,
    SetUpOnly: `${root}/UserSagas/SetUpOnly`,
    Survey: `${root}/Survey`,
    SendResetPasswordEmail: `${root}/UserSagas/SendResetPasswordEmail`,
    SetNewPassword: `${root}/UserSagas/ChangePassword`,
  };

}

export default urls;
